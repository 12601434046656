var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"materialEdit"},[_c('catalog',{attrs:{"material":_vm.material,"value":_vm.value,"materialData":_vm.materialData},on:{"mat-change":_vm.emit}}),_c('div',{style:({
        position: 'fixed',
        right: 0,
        bottom: 0,
        width: '36rem',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      })},[_c('a-popconfirm',{style:({ marginRight: '8px' }),attrs:{"title":_vm.$t('tipDel'),"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"confirm":_vm.handleDel}},[_c('a-button',{attrs:{"type":"danger"}},[_vm._v("删除")])],1),_c('a-button',{style:({ marginRight: '8px' }),attrs:{"type":"primary"},on:{"click":_vm.handleCreate}},[_vm._v(_vm._s(_vm.$t("addMaterial")))]),(_vm.materialData.id)?_c('a-button',{attrs:{"type":"default"},on:{"click":_vm.handleOk}},[_vm._v(_vm._s(_vm.$t("updateMaterial")))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }