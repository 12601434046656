<template>
	<div class="materialsEdit">
		 <div class="textureList">
			<texture-list></texture-list> 
		 </div>
		 <div class="work" id="materialWork"></div>
		 <material-edit
		 class="material-edit"
		 :visible.sync="showEditor"
		 :materialData="materialData"
		 material="pbr"
		 @ok="handleOk"
		 ></material-edit>
	</div>
</template>

<script>
import { ThreeDScene } from "@/utils/babylonjs/methods";
import MaterialEdit from "@/components/material/MaterialEdit";
import TextureList from "@/components/texture/TextureList.vue";
import {
  modelConfig,
  materials,
  materialWorkConfig,
  materialsData,
} from "@/utils/babylonjs/meshData";
import * as commonFn from "@/utils/babylonjs/common";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");
import uploadCos from "@/utils/upload_to_cos";

export default{
	name:'',
	components:{
		MaterialEdit,
		TextureList
	},
	data(){
		return{
			showEditor:true,
			materialData:{},
			materialParam:{},
		}
	},
	mounted(){
		let materialData = JSON.parse(localStorage.getItem('materialData'));
		materialWorkConfig.boxId = "materialWork";
		let b = new ThreeDScene(materialWorkConfig);
		b.loadMaterial(materialData);
		this.global.NewMaterialScene = b;
		this.materialData = materialData;
		this.materialParam = this.materialData;
	},
	destroyed(){
		this.global.NewMaterialScene.dorelease();
	},
	methods:{
		...mapActions([
		  "getMaterialList",
		  "saveMaterial",
		]),
		handleOk(type) {
		  let loading = this.openLoading();
		  let that = this;
		  let page = this.page;
		  let pageSize = this.pageSize;
		  if (type == "create") {
		    that.materialParam.id = "";
		  } else if (type == "update") {
		    if (!that.materialParam.id) {
		      return;
		    }
		  } else {
		    return;
		  }
		  this.global.NewMaterialScene.screenShot().then((file) => {
		    that.materialParam.param = that.global.NewMaterialScene.getMaterialData(
		      that.materialParam.param
		    );
		    uploadCos([file], "works", (picture) => {
		      that.materialParam.coverpic = picture[0];
		      if (that.materialParam != null) {
		        that.saveMaterial(that.materialParam).then(() => {
					that.$router.push({
						name:'market'
					})
				});
		      }
		      loading.close();
		    });
		  });
		},
	}
}
</script>

<style lang="scss">
.materialsEdit{
	width: 100%;
	height: calc(100% - 52px);
	position: absolute;
	background: #fff;
	.work {
	  width: 100%;
	  height: 100%;
	  display: flex;
	  // flex-direction: column;
	  justify-content: center;
	  align-content: center;
	  background-color: rgba(0, 0, 0, 0.1);
	  position: absolute;
	  top: 0;
	}
	.workEdit{
		position: absolute;
		top: 0;
		right: 0;
	}
	
	.textureList{
		width: 36rem;
		height: 100%;
		position: absolute;
		z-index: 1;
		background: #fff;
		padding-top: 20px;
	}
	
	.material-edit{
		background: #fff;
	}
}
</style>
